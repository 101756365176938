import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)

import moment from 'moment';
export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Facture preforma",
            motCle: "",
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            crudform: {
                id:'',
                numero_facture:'',
                count_facture:'',
                total_facture:'',
                id_client:'',
                nom_client:'',
                date_facture_formatted:moment().format('DD/MM/YYYY'),
                date_facture:'',
                detail:[]
            },
            btn_submit:false,
            DossierList:[],
            info_societe:{},
            client_list:[],
        }
    },
    methods: {
        GetINfoSociete(){
            axios.get(this.BASE_URL + '/infosociete/get').then((response) => {
                this.info_societe = response.data[0];
            })
        },
        printFacture(id){
            window.open(this.BASE_URL + "/facturepreforma/imprimerfacture/" + id, '_blank');
        },
        calculMontantFacture(){
            var total = 0;
            this.crudform.detail.forEach(value => {
                total += parseFloat(value.montant)
            });
            this.crudform.total_facture = total;
        },
        getclient() {
            axios.get(this.BASE_URL + "/client/fetchdata").then(response => {
                this.client_list = response.data.client;
            });
        },
        getNumeroFacture(){
            axios.get(this.BASE_URL + "/facturepreforma/getnumerofacturefront").then(response => {
                this.crudform.count_facture = response.data.count_facture;
                this.crudform.numero_facture = response.data.numero_facture;
            });
        },
        openData(){
            if (parseInt(this.$route.params.id_facture_preforma) > 0) {
                this.getFacturePreforma( parseInt(this.$route.params.id_facture_preforma));
            }else{
                this.crudform= {
                    id: "",
                    numero_facture:'',
                    total_facture:'',
                    id_client:'',
                    nom_client:'',
                    date_facture_formatted:moment().format('DD/MM/YYYY'),
                    date_facture:'',
                    detail:[{
                        id_facture_preformat:'',
                        ref:'',
                        description:'',
                        montant:0
                    }]
                };
            }

        },

        pushDetail(){
            this.crudform.detail.push({
                id_facture_preformat: 0,
                ref:'',
                description: '',
                montant: 0
            })
        },
        GotoFacturePreformaList(){
            this.$router.push("/facturepreforma/liste");
        },
        spliceDetail(index){
            this.crudform.detail.splice(index,1);
        },

        onSubmit() {
            var that = this;

            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = '';
                        that.btn_submit = true;

                        link = that.crudform.id == '' ? that.BASE_URL + "/facturepreforma/addaction" : that.BASE_URL + "/facturepreforma/editaction";

                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            that.btn_submit = false;
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                                that.getFacturePreforma(response.data.id);
                                that.printFacture(response.data.id);
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/tresoreries/fetchdata").then(response => {
                console.log(response)
                this.listdata = response.data;
            });
        },
        getFacturePreforma(id){
            axios.get(this.BASE_URL + '/facturepreforma/get/'+id).then((response) => {
                this.crudform = response.data[0];
            })
        },

    },

    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
        this.GetINfoSociete();
        this.getclient();
        this.getNumeroFacture()
    },
    mounted: function() {
        this.openData();

    }
}
